<script lang="ts" setup>
import type { LightCashResponse } from "@/types";

defineProps<{
	presets: LightCashResponse["packages"];
	activeIndex: number | null;
}>();
const emit = defineEmits<{ (event: "clickPreset", value: number): void }>();

const handleClickPreset = (value: number) => {
	emit("clickPreset", value);
};

const formattedMoney = (value: number) => {
	const parts = value.toFixed(2).split(".");
	return `$${parts[0]}<sup>${parts[1] || "00"}</sup>`;
};
</script>
<template>
	<div class="presets">
		<div
			v-for="(item, index) in presets"
			:key="item.money"
			:class="['preset-item', { 'is-active': activeIndex === index }]"
			@click="handleClickPreset(index)"
		>
			<NuxtIcon v-if="item.promoOfferPreset" name="48/reward" filled />
			<AText :modifiers="['bold']">
				<span v-html="formattedMoney(item.money)" />
			</AText>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.presets {
	display: flex;
	flex-wrap: wrap;
	gap: gutter(0.5);
}

.preset-item {
	width: 98px;
	height: 36px;
	border-radius: 26px;
	border: 1px solid var(--cairns);
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--chinatown);
	transition: 0.3s;

	@media (max-width: 465px) {
		width: 32%;
	}

	.nuxt-icon {
		font-size: 20px;
		margin-right: gutter(0.25);
	}

	&:deep(sup) {
		font-size: 10px;
		top: -3px;
		left: 1px;
	}

	&:not(.is-active):hover {
		cursor: pointer;
		opacity: 0.8;
	}

	&.is-active {
		background-color: var(--cairns);
		color: var(--cannes);
	}
}
</style>
